/**
 * Used on the pages directly to add the corret page padding.
 */

import { cx } from "class-variance-authority";
import { ReactNode } from "react";

interface Params {
  className?: string;
  children: ReactNode;
}

export default function Container({ className, children }: Params) {
  return <div className={cx("container w-full max-w-[1280px] mx-auto py-10 px-3 md:px-5", className)}>{children}</div>;
}
